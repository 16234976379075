import React from 'react'
import Heading from './elements/heading2'
import Ast from './ast'
import ButtonFullLg from './button-full-lg'
import Carousel from './featured-carousel'
import Section from './section'

export default ({
  services: {
    title,
    body,
    serviceList,
    button,
    path: { url },
  },
}) => {
  return (
    <Section>
      <Heading>{title}</Heading>
      <Ast ast={body.childMarkdownRemark.htmlAst} />
      <Carousel list={serviceList} />
      {button && url && <ButtonFullLg to={url}>{button}</ButtonFullLg>}
    </Section>
  )
}
