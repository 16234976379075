import React from 'react'
import v4 from 'uuid'
import PostCard from './post-card'
import Heading2 from './elements/heading2'
import ButtonFullLg from './button-full-lg'
import Section from './section'

export default ({ posts: { title, postList, button, url } }) => (
  <Section>
    <Heading2>{title}</Heading2>

    {postList.map(({ slug, title, body, date, path, image }) => (
      <PostCard key={v4()} slug={slug} title={title} body={body} date={date} path={path} image={image} />
    ))}

    <ButtonFullLg to={url}>{button}</ButtonFullLg>
  </Section>
)
