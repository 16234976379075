import React from 'react'
import v4 from 'uuid'
import styled from 'styled-components'
import Img from './post-card-img'
import Ast from './ast'
import Heading from './elements/heading3'
import Meta from './post-meta'
import ButtonFull from './button-full'

const Container = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.body};
  border-radius: ${({ theme }) => theme.radii[1]};
  transition: box-shadow ${({ theme }) => theme.timing.fast} ${({ theme }) => theme.transitions.default};
  will-change: box-shadow;
  margin-bottom: ${({ theme }) => theme.verticalRythm[9]};
`

const HeadingContainer = styled.div`
  margin: 0;
`

const StyledHeading = styled(Heading)`
  margin-top: 0;
`

const StyledButtonFull = styled(ButtonFull)`
  margin: ${({ theme }) => theme.verticalRythm[11]} 0;
`

export default ({ title, image, date, body, slug, path: { url } }) => {
  const { excerptAst, timeToRead } = body.childMarkdownRemark

  return (
    <div key={v4()}>
      <Img fluid={image.fluid} />
      <Container>
        <HeadingContainer>
          <StyledHeading>
            {title}
          </StyledHeading>
          <Meta
            date={date}
            timeToRead={timeToRead}
          />
        </HeadingContainer>
        <Ast ast={excerptAst} />
        <StyledButtonFull to={`${url}/${slug}`} title={title}>
          Read More
        </StyledButtonFull>
      </Container>
    </div>
  )
}
