import React from 'react'
import PageHeading from './page-heading'
import Ast from './ast'
import Section from './section'

export default ({ hero: { title, body } }) => (
  <Section>
    <PageHeading>{title}</PageHeading>
    <Ast ast={body.childMarkdownRemark.htmlAst} />
  </Section>
)
