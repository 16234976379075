import styled from 'styled-components'
import Img from 'gatsby-image'

const breakpoint = ({ theme }) => `${theme.media.sm}`


export default styled(Img)`
  margin-left: -${({ theme }) => theme.verticalRythm[9]};
  margin-right: -${({ theme }) => theme.verticalRythm[9]};

  ${breakpoint} {
    margin-left: 0;
    margin-right: 0;
  }
`