import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import ButtonFullLg from './styles/button-full-lg'

const StyledButtonFullLg = styled(Link)`
  ${ButtonFullLg}
`

export default ({ children, className, to }) => (
  <StyledButtonFullLg
    to={to}
    className={className}
  >
    {children}
  </StyledButtonFullLg>
)
