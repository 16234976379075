import { css } from 'styled-components'
import ButtonFull from './button-full'

export default css`
  ${ButtonFull}

  border-width: ${({ theme }) => theme.borderWidth[2]};
  font-size: ${({ theme }) => theme.fontSizes[10]};
  line-height: ${({ theme }) => theme.verticalRythm[10]};
  padding: ${({ theme }) => theme.verticalRythm[9]};
`
