import React from 'react'
import styled from 'styled-components'

const Section = styled.section`
  margin: 0 0 ${({ theme }) => theme.verticalRythm[12]} 0;
`

export default ({ children }) => (
  <Section>
    {children}
  </Section>
)