import React from 'react'
import Heading2 from './elements/heading2'
import Ast from './ast'
import FaqList from './faq-list'
import ButtonFullLg from './button-full-lg'
import Section from './section'

export default ({ faqs: { title, body, faqList, button, url } }) => {
  const htmlAst = body.childMarkdownRemark.htmlAst

  return (
    <Section>
      <Heading2>{title}</Heading2>
      <Ast ast={htmlAst} />
      <FaqList faqList={faqList} as="h3" />
      <ButtonFullLg to={url}>{button}</ButtonFullLg>
    </Section>
  )
}
