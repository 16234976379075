import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Hero from '../components/hero'
import Services from '../components/featured-services'
import Faqs from '../components/faqs'
import Posts from '../components/featured-posts'
import Seo from '../components/seo'

export default ({ data }) => {
  const node = data.allContentfulHomePage.edges[0].node
  const { hero, services, faqs, posts } = node

  return (
    <Layout customSeo={`true`}>
      <Seo homePage={node} />
      <Hero hero={hero} />
      <Services services={services} />
      <Faqs faqs={faqs} />
      <Posts posts={posts} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query IndexPage {
    allContentfulHomePage {
      edges {
        node {
          hero {
            title
            body {
              childMarkdownRemark {
                html
                htmlAst
              }
            }
          }
          services {
            title
            body {
              childMarkdownRemark {
                html
                htmlAst
              }
            }
            button
            path {
              url
            }
            serviceList {
              slug
              title
              service {
                path {
                  url
                }
                title
                description {
                  childMarkdownRemark {
                    html
                    htmlAst
                  }
                }
              }
            }
          }
          faqs {
            title
            body {
              childMarkdownRemark {
                html
                htmlAst
              }
            }
            faqList {
              question
              answer {
                childMarkdownRemark {
                  html
                  htmlAst
                }
              }
            }
            button
            url
          }
          posts: blogPosts {
            title
            postList: blogPostList {
              slug
              title
              image {
                fluid(maxWidth: 800, quality: 100) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                }
              }
              date(formatString: "MMMM Do, YYYY")
              path {
                url
              }
              body {
                childMarkdownRemark {
                  excerpt(pruneLength: 200)
                  excerptAst(pruneLength: 200)
                  timeToRead
                }
              }
            }
            button
            url
          }
        }
      }
    }
  }
`
